import { I18n } from 'i18n-js';
import uk from './translations/uk';
import en from './translations/en';

const i18n = new I18n({
    uk,
    en
});

// Get saved language from localStorage or default to Ukrainian
const savedLanguage = localStorage.getItem('language') || 'uk';
i18n.defaultLocale = savedLanguage;
i18n.locale = savedLanguage;

// Enable fallbacks if you want
i18n.enableFallback = true;

export const setLanguage = (lang) => {
    i18n.locale = lang;
    localStorage.setItem('language', lang);
};

export default i18n;
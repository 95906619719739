export default {
    common: {
        appName: 'Book-O-Bot',
        upload: 'Завантажити',
        login: 'Увiйти',
        logout: 'Вийти',
        books: 'книги',
        loading: 'Завантаження...',
        close: 'Закрити',
        ok: 'OK!'
    },
    auth: {
        loginToUpload: 'Увiйдiть, щоб завантажити книгу',
        enterEmail: 'Введiть email для входу',
        emailPlaceholder: 'ваш.email@gmail.com',
        passwordPlaceholder: 'Пароль',
        confirmPasswordPlaceholder: 'Пiдтвердiть пароль',
        updatePassword: 'Оновити пароль',
        passwordTooShort: 'Пароль має бути не менше 6 символiв',
        enterPassword: 'Будь ласка, введiть пароль та пiдтвердiть його',
        passwordsDontMatch: 'Паролi не спiвпадають',
        passwordUpdateError: 'Помилка оновлення пароля',
        passwordUpdateSuccess: 'Пароль успiшно оновлено',
    },
    books: {
        noBooksPlaceholder: 'Тут будуть вашi книги. Завантажте PDF, щоб почати.',
        uploadBook: 'Завантажте книгу',
        processing: 'Обробка...'
    },
    flashcards: {
        question: 'П',
        answer: 'В',
        downloadAnki: 'Завантажити для Anki',
        downloadQuizlet: 'Завантажити для Quizlet',
    },
    footer: {
        price: '₴20 000',
        termsOfService: 'Умови використання',
        privacyPolicy: 'Полiтика конфiденцiйностi',
        refundPolicy: 'Полiтика повернення коштiв',
    },
    loading: {
        uploading: 'Завантаження вашої книги...',
        processing: 'Обробка вашої книги...',
        creating: 'Створення карток...',
        almostDone: 'Майже готово...',
        justAMoment: 'Ще трохи...',
        robotWorking: 'Робот працює...',
        robotDoing: 'Роботи роблять те, що роблять роботи...',
        anySecond: 'Ось-ось...',
        fewMoreSeconds: 'Ще кiлька секунд...',
        binary: '1100001,1100001',
    },
    landing: {
        titles: [
            'Вчись. Швидше. Краще.',
            'Отримай картки. Склади iспити.',
            'Вчись розумнiше. Не важче.'
        ],
        texts: [
            'Не витрачай час - вчись швидше з Book-O-Bot',
            'Перетвори свої книги на картки для запам\'ятовування',
            'Склади наступний iспит з легкiстю — без нескiнченного видiлення тексту!',
            'Економ грошi. Без зайвого. Тiльки картки.'
        ],
        buttons: [
            'Спробувати зараз',
            'Склади iспит',
            'Отримай 3 безкоштовнi токени',
            'Отримай безкоштовнi хаки для навчання'
        ],
        salaryTarget: 'Ваша зарплата',
        salaryNote: 'Розвивай знання, збiльшуй дохiд'
    },
    tutorial: {
        welcome: 'Привiт!',
        freeTokens: 'У вас 3 безкоштовнi токени',
        steps: {
            upload: 'Натиснiть Завантажити',
            wait: 'Зачекайте трошки',
            getFlashcards: 'Отримайте картки',
            answerQuestions: 'Вiдповiдайте на питання',
            profit: 'Ваша очiкувана зарплата росте!'
        },
        letsGo: 'Вперед!',
        tempPassword: 'Ваш тимчасовий пароль: Hello123',
        updatePasswordNote: 'Будь ласка, змiнiть пароль якнайшвидше в Профiлi користувача'
    },
    userProfile: {
        title: 'Профiль користувача'
    },
    salary: {
        growthMessage: 'Ваша очiкувана зарплата зростає, коли ви вчитеся та вiдповiдаєте на питання!',
        wrongAnswersMessage: 'Неправильнi вiдповiдi також сприяють вашим знанням та зарплатi, але правильнi вiдповiдi звiсно кращi!'
    },
    flashcard: {
        rating: {
            incorrect: 'Невiрно',
            almost: 'Майже',
            correct: 'Вiрно'
        }
    }
}; 
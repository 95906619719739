import React from "react";
import {logEvent} from "@firebase/analytics";
import {analytics} from "./App";
import i18n from './i18n';
import { useLanguage } from './LanguageContext';

function Landing(props) {
    const { currentLanguage } = useLanguage();

    const getRandomTryNowButtonText = () => {
        const buttonText = i18n.t('landing.buttons')[Math.floor(Math.random() * 4)];
        logEvent(analytics, "try_now_button_text", {text: buttonText});
        return buttonText;
    };

    const getRandomLandingText = () => {
        const landingText = i18n.t('landing.texts')[Math.floor(Math.random() * 4)];
        logEvent(analytics, "landing_text", {text: landingText});
        return landingText;
    };

    const getRandomLandingTitle = () => {
        const landingTitle = i18n.t('landing.titles')[Math.floor(Math.random() * 3)];
        logEvent(analytics, "landing_title", {title: landingTitle});
        return landingTitle;
    };

    return <div className="landing">
        <div className="landing-left">
            <h4>{getRandomLandingTitle()}</h4>
            <hr/>
            <p>{getRandomLandingText()}</p>
            <button className="landing-button btn btn-success"
                    onClick={props.onClick}>{getRandomTryNowButtonText()}
            </button>
        </div>
        <div className="landing-right">
            <img className="landing-right-image" src="/images/bookobot-process-long.png"
                 alt="Bookobot process explained"/>
        </div>
    </div>;
}

export default Landing;
import React, {useState} from "react";
import {analytics, auth} from "./App";
import {logEvent} from "@firebase/analytics";
import { updatePassword } from 'firebase/auth';
import i18n from './i18n';
import { useLanguage } from './LanguageContext';

function UserProfile() {
    const { currentLanguage } = useLanguage();
    const [message, setMessage] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const handleUpdatePassword = async () => {
        if (password.length < 6) {
            logEvent(analytics, "password_too_short");
            setMessage(i18n.t('auth.passwordTooShort'));
            return;
        }
        if (!password || !passwordConfirm) {
            logEvent(analytics, "password_empty");
            setMessage(i18n.t('auth.enterPassword'));
            return;
        }
        if (password !== passwordConfirm) {
            logEvent(analytics, "passwords_do_not_match");
            setMessage(i18n.t('auth.passwordsDontMatch'));
            return;
        }

        try {
            const user = auth.currentUser;
            await updatePassword(user, password).then(() => {
                logEvent(analytics, "password_updated");
            });
        } catch (error) {
            console.log("Error updating password:", error);
            setMessage(i18n.t('auth.passwordUpdateError'));
            logEvent(analytics, "password_update_error");
            return;
        }
        setPassword("");
        setPasswordConfirm("");
        setMessage(i18n.t('auth.passwordUpdateSuccess'));
    }

    return (
        <div className="d-flex flex-column align-items-center gap-2">
            <h1>{i18n.t('userProfile.title')}</h1>
            {message && (<p className="auth-notification-message">{message}</p>)}
            <input
                className="container user-profile-password-input"
                type="password"
                placeholder={i18n.t('auth.passwordPlaceholder')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <input
                className="container user-profile-password-input"
                type="password"
                placeholder={i18n.t('auth.confirmPasswordPlaceholder')}
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            <button className="update-password-button" onClick={handleUpdatePassword}>
                {i18n.t('auth.updatePassword')}
            </button>
        </div>
    );
}

export default UserProfile;
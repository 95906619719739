// ModalLink.js
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Modal } from 'bootstrap';
import React, { useRef } from 'react';

// Important: Make sure Bootstrap JS is available globally or imported in your project

function ModalLink({ linkText, title, children }) {
    const modalRef = useRef(null);  // we'll attach this to the modal DOM node

    const handleOpen = () => {
        if (modalRef.current) {
            // Programmatically create a Bootstrap Modal and show it
            const modal = new Modal(modalRef.current);
            modal.show();
        }
    };

    return (
        <>
            {/* Underlined "link" that triggers the modal */}
            <span
                onClick={handleOpen}
                style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '0.8rem' }}
            >
        {linkText}
      </span>

            {/* The modal itself */}
            <div
                className="modal fade"
                ref={modalRef}
                tabIndex="-1"
                aria-hidden="true"
            >
                <div className="modal-dialog" style={{ maxWidth: '90%', margin: '5rem auto', color: 'black' }}>
                    <div className="modal-content">
                        {/* Modal Header */}
                        <div className="modal-header d-flex justify-content-center">
                            <h5 className="modal-title">{title}</h5>
                        </div>

                        {/* Modal Body */}
                        <div className="modal-body text-start">
                            {children}
                        </div>

                        {/* Modal Footer */}
                        <div className="modal-footer d-flex justify-content-center">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalLink;

export default {
    common: {
        appName: 'Book-O-Bot',
        upload: 'Upload',
        login: 'Login',
        logout: 'Logout',
        books: 'books',
        loading: 'Loading...',
        close: 'Close',
        ok: 'OK!'
    },
    auth: {
        loginToUpload: 'Please log in to upload a book',
        enterEmail: 'Please enter your email to log in',
        emailPlaceholder: 'youremail@gmail.com',
        passwordPlaceholder: 'Password',
        confirmPasswordPlaceholder: 'Confirm Password',
        updatePassword: 'Update Password',
        passwordTooShort: 'Password must be at least 6 characters long',
        enterPassword: 'Please enter a password and confirm it',
        passwordsDontMatch: 'Passwords do not match',
        passwordUpdateError: 'Error updating password',
        passwordUpdateSuccess: 'Password updated successfully',
    },
    books: {
        noBooksPlaceholder: 'Your books will be here. Upload a PDF to get started.',
        uploadBook: 'Upload a book',
        processing: 'Processing...'
    },
    flashcards: {
        question: 'Q',
        answer: 'A',
        downloadAnki: 'Download for Anki',
        downloadQuizlet: 'Download for Quizlet',
    },
    footer: {
        price: '₴20 000',
        termsOfService: 'Terms of Service',
        privacyPolicy: 'Privacy Policy',
        refundPolicy: 'Refund Policy',
    },
    loading: {
        uploading: 'Uploading your book...',
        processing: 'Processing your book...',
        creating: 'Creating flashcards...',
        almostDone: 'Almost there...',
        justAMoment: 'Just a moment...',
        robotWorking: 'Robot is working...',
        robotDoing: 'Robots doing what robots do best...',
        anySecond: 'Any second now...',
        fewMoreSeconds: 'Just a few more seconds...',
        binary: '1100001,1100001',
    },
    landing: {
        titles: [
            'Learn. Faster. Better.',
            'Get Flashcards. Ace Exams.',
            'Study Smarter. Not Harder.'
        ],
        texts: [
            'Don\'t Waste Your Time - Learn Faster With Book-O-Bot',
            'Turn Your Books into Memory - Boosting Flashcards',
            'Ace Your Next Exam With Effortless Flashcards — No More Endless Highlighting!',
            'Save Money. No Nonsense. Just Flashcards.'
        ],
        buttons: [
            'Try It Now',
            'Ace Your Exam',
            'Claim My 3 Free Book Tokens',
            'Get My Free Study Hacks Now'
        ],
        salaryTarget: 'Expected Monthly Salary',
        salaryNote: 'Grow your knowledge, grow your worth'
    },
    tutorial: {
        welcome: 'Welcome',
        freeTokens: 'You have 3 Free Book Tokens',
        steps: {
            upload: 'Tap on Upload',
            wait: 'Wait juuust a tiny bit',
            getFlashcards: 'Get your flashcards',
            answerQuestions: 'Answer questions',
            profit: 'Your expected salary grows!'
        },
        letsGo: 'Let\'s go!',
        tempPassword: 'Your temporary password is: Hello123',
        updatePasswordNote: 'Please, update password ASAP in User Profile'
    },
    userProfile: {
        title: 'User Profile'
    },
    salary: {
        growthMessage: 'Your expected salary grows when you learn and answer questions!',
        wrongAnswersMessage: 'Wrong answers also contribute to your knowledge and salary but correct answers are better of course.'
    },
    flashcard: {
        rating: {
            incorrect: 'Wrong',
            almost: 'Almost',
            correct: 'Correct'
        }
    }
}; 
import React from "react";

function SideMenu(props) {
    return <div className="side-menu-overlay" onClick={props.onClick}>
        <div className="side-menu-overlay-content d-flex flex-column flex-shrink-0">
            <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
                <li className="nav-item" onClick={props.onClick1}>
                    <i className="bi-house-door fs-3 nav-link py-3 side-menu-item border-3 border-bottom border-dark-subtle rounded-0"></i>
                </li>
                <li className="nav-item" onClick={props.onClick2}>
                    <i className="bi-person fs-3 nav-link py-3 side-menu-item border-3 border-bottom border-dark-subtle rounded-0"></i>
                </li>
                <li className="nav-item" onClick={props.onClick3}>
                    <i className="bi-box-arrow-right fs-3 nav-link py-3 side-menu-item"></i>
                </li>
            </ul>
        </div>
    </div>;
}

export default SideMenu;
import React, {useEffect, useState} from "react";
import {
    GoogleAuthProvider,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    verifyEmail,
    applyActionCode,
    sendPasswordResetEmail,
    verifyPasswordResetCode,
    confirmPasswordReset
} from "firebase/auth";
import {analytics, auth} from "./App";
import {logEvent, setUserId} from "@firebase/analytics";
import {useAuth} from "./AuthContext";

const domainUrl = "https://api.bookobot.com";

function AuthWithFirebase() {
    const [loading, setLoading] = useState(false); // To handle loader visibility
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userExists, setUserExists] = useState(false);
    const {setFirstTimeLogin, authMessage, setAuthMessage, setUserLoggedIn} = useAuth(); // Access global state
    const [isResetingPassword, setIsResetingPassword] = useState(false);
    const [resetMode, setResetMode] = useState(false); // Tracks if we're in reset mode

    const handleSendResetEmail = async () => {
        if (!email) {
            setAuthMessage("Please enter your email address.");
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            setAuthMessage("Password reset email sent. Please check your inbox.");
        } catch (error) {
            console.error("Error sending password reset email:", error);
            setAuthMessage("Failed to send password reset email. Please try again.");
        }
    }

    const handleResetPassword = async () => {
        if (!password) {
            setAuthMessage("Please enter a new password.");
            return;
        }

        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            const oobCode = urlParams.get("oobCode");

            await confirmPasswordReset(auth, oobCode, password); // Reset the password
            setAuthMessage("Password has been successfully reset.");

            // Clear query params from the URL
            const baseUrl = window.location.origin + window.location.pathname;
            window.history.replaceState(null, "", baseUrl);

            // Exit reset mode
            setResetMode(false);
        } catch (error) {
            console.error("Error resetting password:", error);
            setAuthMessage("Failed to reset password. The link may be invalid or expired.");
        } finally {
            setLoading(false);
            // Clear query params from the URL
            clearUrlParams();
            setAuthMessage("Password has been successfully reset.");
            setPassword("");
        }
    };

    const handlePasswordResetLink = async (oobCode) => {
        try {
            setResetMode(true);
            setAuthMessage("Enter a new password");
            const email = await verifyPasswordResetCode(auth, oobCode);

        } catch (error) {
            console.error("Invalid or expired password reset link:", error);
            alert("This password reset link is invalid or has expired.");
        }
    }

    const clearUrlParams = () => {
        const baseUrl = window.location.origin + window.location.pathname; // Preserve base URL and path
        window.history.replaceState(null, "", baseUrl); // Update URL without query parameters
    };

    // Check for email verification link
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const mode = urlParams.get("mode");
        const oobCode = urlParams.get("oobCode");

        if (mode === "verifyEmail" && oobCode) {
            // verifyEmail(oobCode);
        }

        if (mode === "resetPassword") {
            handlePasswordResetLink(oobCode);
        }
    }, []);

    const handleGoogleSignIn = async () => {
        logEvent(analytics, "google_sign_in_clicked");
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            logEvent(analytics, "google_sign_in_successful");
            const user = result.user;

            await saveUserToDatabase(user);
            setUserLoggedIn(true); // Update global state
            setUserAnalyticsId(user.uid);
            logEvent(analytics, "google_sign_in_user_saved_to_db");
            setLoading(false); // Hide loader
        } catch (error) {
            console.error("Google Sign-In Error:", error);
            setAuthMessage("Failed to sign in with Google. Try again.");
            logEvent(analytics, "google_sign_in_error", {error: error.message});
            setLoading(false); // Hide loader
            setUserLoggedIn(false); // Update global state
        }
        setPassword("");
    };

    function setUserAnalyticsId(userId) {
        setUserId(analytics, userId);
    }

    const saveUserToDatabase = async (user) => {
        try {
            const idToken = await user.getIdToken(); // Get Firebase ID token


            const response = await fetch(domainUrl + "/api/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${idToken}` // Send token in the Authorization header
                },
                body: JSON.stringify({
                    uid: user.uid,
                    phoneNumber: user.phoneNumber,
                    email: user.email,
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to save user information");
            }
            // Check user logging in first time
            const data = await response.json();
            if (data.firstLogin) {
                setFirstTimeLogin(true);
                logEvent(analytics, "new_user_logged_in");
            } else {
                setFirstTimeLogin(false);
                logEvent(analytics, "returning_user_logged_in");
            }

        } catch (error) {
            console.error("Error saving user information:", error);
        }
    };

    const checkUserExists = async (email) => {
        try {
            const response = await fetch(domainUrl + "/api/user", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to check user information");
            }
            // Check user logging in first time
            const data = await response.json();
            return data.exists;
        } catch (error) {
            console.error("Error saving user information:", error);
        }
    }

    const handleRegister = async (passwordParam) => {
        setLoading(true);
        setAuthMessage("");

        try {
            const result = await createUserWithEmailAndPassword(auth, email, passwordParam);
            const user = result.user;

            await saveUserToDatabase(user);
            // setAuthMessage("Verification email has been sent. Please check your inbox.");
            logEvent(analytics, "email_registration_successful");
            //
            // // Send verification email
            // await sendEmailVerification(user);
        } catch (error) {
            console.error("Error during registration:", error);
            setAuthMessage("Failed to register. Please try again.");
            logEvent(analytics, "email_registration_error", {error: error.message});
        } finally {
            setLoading(false);
            setPassword("");
        }
    };

    const verifyEmail = async (oobCode) => {
        try {
            setLoading(true);
            await applyActionCode(auth, oobCode);

            const user = auth.currentUser;
            await user.reload();
            if (user && user.emailVerified) {
                await saveUserToDatabase(user);
                setUserLoggedIn(true); // Update global state
                setAuthMessage("Email verified successfully!");
                logEvent(analytics, "email_verification_successful");
            } else {
                setAuthMessage("Failed to verify email. Please try again.");
                logEvent(analytics, "email_verification_error");
            }
        } catch (error) {
            console.error("Error verifying email:", error);
            setAuthMessage("Invalid or expired verification link. Please try again.");
            setUserLoggedIn(false);
        } finally {
            setLoading(false);
            window.history.replaceState(null, "", "https://bookobot.com");
        }
    };

    const handleLogin = async () => {
        setLoading(true);
        setAuthMessage("");

        try {
            const result = await signInWithEmailAndPassword(auth, email, password);
            const user = result.user;

            // Check if email is verified
            await user.reload(); // Refresh user data
            if (!user.emailVerified) {
                setAuthMessage("Email is not verified. Please check your inbox.");
                return;
            }
            setUserLoggedIn(true);
            setAuthMessage("Login successful!");
            logEvent(analytics, "email_login_successful");

            // Proceed with the application flow for verified users
        } catch (error) {
            setUserLoggedIn(false);
            console.error("Error during login:", error);
            setAuthMessage("Failed to log in. Please check your credentials. (Temporary password is Hello123)");
            logEvent(analytics, "email_login_error", {error: error.message});
        } finally {
            setLoading(false);
            setPassword("");
        }
    };

    const isEmailValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    function generateTemporaryPassword(length = 8) {
        let password = 'Hello123';
        // const chars =
        //     'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:\'",.<>?';
        //
        // for (let i = 0; i < length; i++) {
        //     const randomIndex = Math.floor(Math.random() * chars.length);
        //     password += chars[randomIndex];
        // }

        return password;
    }

    const handleEnteredEmail = async () => {
        if (!email || !isEmailValid(email)) {
            logEvent(analytics, "invalid_email");
            setAuthMessage("Please enter a valid email address.");
            return;
        }

        if (!password && await checkUserExists(email)) {
            logEvent(analytics, "email_exists", {email: email});
            setAuthMessage("Please, enter your password");
            setUserExists(true);
            setLoading(false);
            return;
        }
        if (password) {
            await handleLogin();
            return;
        }
        const newPassword = generateTemporaryPassword(12);
        await handleRegister(newPassword);
        setUserLoggedIn(true);
        logEvent(analytics, "email_entered", {email: email});
        setLoading(false);
    }

    return (
        <>
            {!loading && (
                <>
                    {!isResetingPassword && !resetMode ? (
                        <>
                            <div className="email-auth">
                                {authMessage && (<p className="auth-notification-message">{authMessage}</p>)}
                                <input
                                    className="container"
                                    type="email"
                                    placeholder="youremail@gmail.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    hidden={userExists}
                                />
                                <input
                                    type="password"
                                    placeholder="drowssaP"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    hidden={!userExists}
                                />
                                {/*<button className="login-button" onClick={handleLogin} disabled={loading}>*/}
                                {/*    Login*/}
                                {/*</button>*/}
                                {/*<button className="login-button" onClick={handleRegister} disabled={loading}>*/}
                                {/*    Register*/}
                                {/*</button>*/}
                                {/*<button onClick={() => {*/}
                                {/*    setIsResetingPassword(true);*/}
                                {/*    setMessage("");*/}
                                {/*}} className="forgot-password-button">Forgot Password*/}
                                {/*</button>*/}
                                <button className="login-button" onClick={handleEnteredEmail} disabled={loading}>
                                    Go
                                </button>
                            </div>

                            <div>
                                <div className="divider"></div>
                                <button className="container google-signin-button" onClick={handleGoogleSignIn}>
                                    Sign in with Google
                                </button>
                            </div>
                        </>) : (
                        <>
                            {resetMode ? (
                                <div className="email-auth">
                                    <p className="auth-notification-message">{authMessage}</p>
                                    <input
                                        type="password"
                                        placeholder="Enter new password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <button className="reset-password-button" onClick={handleResetPassword}
                                            disabled={loading}>
                                        Reset Password
                                    </button>
                                </div>) : (
                                <div className="email-auth">
                                    <p className="auth-notification-message">{authMessage}</p>
                                    <input
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <button onClick={handleSendResetEmail} className="forgot-password-button">Send Reset
                                        Email
                                    </button>
                                </div>)}
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default AuthWithFirebase;

import React from "react";
import i18n from './i18n';
import { useLanguage } from './LanguageContext';

function Tutorial(props) {
    const { currentLanguage } = useLanguage();

    const handleTutorialGoClicked = () => {
        props.onClick();
    }
    return <div className="tutorial" onClick={props.onClick}>
        <div className="tutorial-content">
            <h4>{i18n.t('tutorial.welcome')}</h4>
            <hr/>
            <span className="h6">{i18n.t('tutorial.freeTokens')} <i className="bi-book"></i></span>
            <hr/>
            <span>1. {i18n.t('tutorial.steps.upload')} <label
                className="btn btn-success btn-lg border-3 border-white">
                        <i className="bi-cloud-upload"></i>
                        <input
                            className="d-none"
                            type="file"
                            accept="application/pdf"
                            disabled={true}
                        />
                    </label></span>
            <p>2. {i18n.t('tutorial.steps.wait')}</p>
            <p>3. {i18n.t('tutorial.steps.getFlashcards')}</p>
            <p>4. {i18n.t('tutorial.steps.answerQuestions')}</p>
            <p>4. {i18n.t('tutorial.steps.profit')}</p>
            <button id="tutorial-go-button" className="btn btn-success text-uppercase" onClick={handleTutorialGoClicked}>
                {i18n.t('tutorial.letsGo')}
            </button>
            <br/>
            <br/>
            <small><u>{i18n.t('tutorial.tempPassword')}</u></small>
            <br/>
            <small><u>{i18n.t('tutorial.updatePasswordNote')}</u></small>
        </div>
    </div>;
}

export default Tutorial;
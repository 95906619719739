import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "./App.css";
import AuthWithFirebase from "./AuthWithFirebase";
import 'firebaseui/dist/firebaseui.css'
import {getAuth, setPersistence, browserLocalPersistence, signOut} from "firebase/auth";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {logEvent, setUserId} from "@firebase/analytics";
import {useAuth} from "./AuthContext";
import {IoCloudDownloadOutline} from "react-icons/io5";
import UserProfile from "./UserProfile";
import SideMenu from "./SideMenu";
import Tutorial from "./Tutorial";
import Landing from "./Landing";
import sampleBooks from "./data/sampleBooks.json";
import Footer from "./Footer";
import LanguageSelector from "./components/LanguageSelector";
import i18n from './i18n';
import { LanguageProvider, useLanguage } from './LanguageContext';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAo9_vuMC42ZFAzG8nc646XbDbMgImbUhE",
    authDomain: "bookobot.com",
    projectId: "bookwit",
    storageBucket: "bookwit.firebasestorage.app",
    messagingSenderId: "493730830470",
    appId: "1:493730830470:web:a6640fd2796e8c7f5cd907",
    measurementId: "G-V9JLFSJMLF"
};

const paypalConfig = {
    "client-id": "AbNyDa4YWsV3rxQcDycSveXXUj3lSnizL6hf5u4nV0Q20KhZVzUFSWsQdVHLbR2QIy-GQfxcUH0cWo3C",
    currency: "USD",
    intent: "capture",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export {analytics};
export const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence)
    .then(() => {
    })
    .catch((error) => {
    });

const domainUrl = "https://api.bookobot.com";

function App() {
    const { currentLanguage } = useLanguage();
    const [flashCards, setFlashCards] = useState(() => {
        const savedFlashcards = localStorage.getItem('flashcards');
        return savedFlashcards ? JSON.parse(savedFlashcards) : [];
    });
    const [processingBookFlashcards, setProcessingBookFlashcards] = useState(null);
    const [currentFlashcardIndex, setCurrentFlashcardIndex] = useState(0);
    const [showAnswer, setShowAnswer] = useState(false);
    const [selectedRating, setSelectedRating] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [books, setBooks] = useState(() => {
        const savedBooks = localStorage.getItem('books');
        return savedBooks ? JSON.parse(savedBooks) : sampleBooks;
    });
    const [currentBook, setCurrentBook] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [paymentOverlayMessage, setPaymentOverlayMessage] = useState("");
    const [processingMessage, setProcessingMessage] = useState("");
    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const [userProfileOpened, setUserProfileOpened] = useState(false);
    const [shouldShowSalary, setShouldShowSalary] = useState(false);
    const [expectedSalary, setExpectedSalary] = useState(20000);
    const {
        user,
        userLoggedIn,
        setUserLoggedIn,
        showOverlay: showPaymentOverlay,
        setShowOverlay,
        loading,
        firstTimeLogin,
        setFirstTimeLogin,
        setAuthMessage
    } = useAuth();
    const [bookTokens, setBookTokens] = useState(0);
    const [isPolling, setIsPolling] = useState(false);
    const [checkProcessingInProgress, setCheckProcessingInProgress] = useState(false);
    const [landing, setLanding] = useState(true);
    const [shouldShowUploadButton, setShouldShowUploadButton] = useState(false);
    const [shouldShowLoadingMessage, setShouldShowLoadingMessage] = useState(false);
    const [shouldShowUserProfile, setShouldShowUserProfile] = useState(false);
    const [shouldShowTutorial, setShouldShowTutorial] = useState(false);
    const [shouldShowBookTokens, setShouldShowBookTokens] = useState(false);
    const [shouldShowBooks, setShouldShowBooks] = useState(false);
    const [shouldShowLanding, setShouldShowLanding] = useState(false);
    const [shouldShowAuth, setShouldShowAuth] = useState(false);
    const [shouldShowLoginButton, setShouldShowLoginButton] = useState(false);
    const [authorizationRequired, setAuthorizationRequired] = useState(false);
    const [feedbackQueue, setFeedbackQueue] = useState([]);
    const [lastFeedbackSentAt, setLastFeedbackSentAt] = useState(null);
    const [showSalaryInfo, setShowSalaryInfo] = useState(false);

    // Add viewport height calculation
    useEffect(() => {
        const setVh = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        setVh();
        window.addEventListener('resize', setVh);
        window.addEventListener('orientationchange', setVh);

        return () => {
            window.removeEventListener('resize', setVh);
            window.removeEventListener('orientationchange', setVh);
        };
    }, []);

    const closePaymentOverlay = () => {
        // Open the PayPal link in a new window
        setShowOverlay(false);
        setPaymentOverlayMessage("");
    };

    const closeTutorialOverlay = () => {
        // Open the PayPal link in a new window
        setFirstTimeLogin(false);
        logEvent(analytics, "tutorial_closed");
        console.log("Tutorial closed");
    };

    const checkBooksInProcessing = async () => {
        if (!auth.currentUser) {
            return;
        }
        if (userLoggedIn) {
            setCheckProcessingInProgress(true);
            const idToken = await auth.currentUser.getIdToken();
            try {
            let response = await fetch(domainUrl + "/api/books/process", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${idToken}`,
                }
            });
            if (!response.ok) {
                throw new Error("Failed to check books in processing");
            }
            const data = await response.json();
                if (data.status && data.status !== "COMPLETED" && data.status !== "ERROR") {
                    pollProcessStatus(idToken);
            }
            } catch (error) {
                console.error("Error checking processing status:", error);
            } finally {
        setCheckProcessingInProgress(false);
            }
        }
    };

    useEffect(() => {
        if (user || !showPaymentOverlay || userLoggedIn) {
            setFlashCards([]);
            checkBooksInProcessing();
            fetchUserBooks();
        }
        if (userLoggedIn) {
            setLanding(false);
            setAuthorizationRequired(false);
        }
        if (!userLoggedIn) {
            // setFlashCards(flashcardsMicroservices);
        }
    }, [user, showPaymentOverlay, userLoggedIn]);

    // Check for email verification link
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const mode = urlParams.get("mode");
        const oobCode = urlParams.get("oobCode");
        const fbRef = urlParams.get("fbRef");

        if (fbRef === "op") {
            setLanding(false);
        }

        if (mode || oobCode) {
            setLanding(false);
        }
    }, []);

    useEffect(() => {
        if (books.length > 0) {
            setCurrentBook(books[books.length - 1]);
            handleBookClick(books[books.length - 1].id);
        }
    }, [books]);

    // Add effect to save books to localStorage when they change
    useEffect(() => {
        localStorage.setItem('books', JSON.stringify(books));
    }, [books]);

    // Add effect to save flashcards to localStorage when they change
    useEffect(() => {
        localStorage.setItem('flashcards', JSON.stringify(flashCards));
    }, [flashCards]);

    const fetchUserBooks = async () => {
        // If not logged in, use data from localStorage
        if (!auth.currentUser) {
            setFlashCards(currentBook?.flashcards || []);
            return;
        }

        try {
            const idToken = await auth.currentUser.getIdToken();
            logEvent(analytics, "fetch_books_started");
            const response = await fetch(domainUrl + "/api/books", {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                logEvent(analytics, "fetch_books_error");
                throw new Error("Failed to fetch books");
            }

            logEvent(analytics, "fetch_books_ended");
            const data = await response.json();
            setBooks(data.books);
            setBookTokens(data.bookTokens);
            console.log(data);
        } catch (error) {
            console.error("Error fetching books:", error);
        }
    };

    // Helper function to "sleep" for a given number of milliseconds
    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const handleUploadClicked = () => {
        if (!userLoggedIn) {
            setAuthMessage("Please log in to upload a book");
            setAuthorizationRequired(true);
            setFlashCards([]);
        }
    }

    const handleLoginClicked = () => {
        setAuthorizationRequired(true);
        setFlashCards([]);
        setAuthMessage("Please enter your email to log in");
    }

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append("file", file);

        try {
            setUploading(true);

            const idToken = await user.getIdToken();
            logEvent(analytics, "file_upload_started");
            const response = await fetch(domainUrl + "/api/upload", {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${idToken}` // Send token in the Authorization header
                },
                body: formData,
            });

            if (!response.ok) {
                if (response.status === 403) {
                    const errorMessage = await response.text();
                    if (errorMessage.startsWith("0001")) {
                        setPaymentOverlayMessage("Book-O-Bot has ran out of charge. Redirecting to charging station...");
                        setShowOverlay(true);
                        if (response.headers.get("Payment-Url")) {
                            await sleep(3000);
                            window.location.href = response.headers.get("Payment-Url");
                        }
                    } else {
                        alert("You are not authorized to perform this action.");
                    }
                } else {
                    throw new Error("Failed to upload file");
                }
                return;
            }

            const data = await response.json();
            logEvent(analytics, "file_upload_ended");

            // Create a new book entry immediately after upload
            const newBook = {
                id: data.bookId,
                title: data.title,
                flashcards: data.flashCards || []
            };
            
            // Add the new book to the beginning of the list
            setBooks(prevBooks => [newBook, ...prevBooks]);
            setCurrentBook(newBook);

            // If we have flashcards already, update them
            if (data.flashCards && data.flashCards.length > 0) {
                setFlashCards(data.flashCards);
                localStorage.setItem(`flashcards_${data.bookId}`, JSON.stringify(data.flashCards));
                setCurrentFlashcardIndex(0);
                setShowAnswer(false);
                setSelectedRating(null);
            }

            // Start polling if processing is not complete
            if (data.status !== "COMPLETED") {
                pollProcessStatus(idToken);
            }
        } catch (error) {
            logEvent(analytics, "file_upload_error");
            console.error("Error uploading file:", error);
            alert("Failed to process the file. Please try again.");
        } finally {
            setUploading(false);
        }
    };

    const pollProcessStatus = async (idToken) => {
        const POLLING_INTERVAL = 5000; // 5 seconds
        setIsPolling(true);
        let polling = true;
        let currentProcessingBookId = null;
        
        while (polling) {
            try {
                const response = await fetch(`${domainUrl}/api/books/process`, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                        "Content-Type": "application/json",
                    }
                });
                
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                
                const data = await response.json();

                // Save the processing book ID when we receive it
                if (data.bookId && !currentProcessingBookId) {
                    currentProcessingBookId = data.bookId;
                }

                // Check if processing is complete or errored
                if (data.status === "ERROR" || data.status === "COMPLETED" || (!data.bookId && currentProcessingBookId)) {
                    polling = false;
                    setIsPolling(false);
                    setProcessingBookFlashcards(null);

                    if (data.status === "ERROR") {
                        alert("An error occurred during book processing. Please try uploading again");
                        // Remove the book from the list if there's an error
                        setBooks(prevBooks => prevBooks.filter(b => b.id !== currentProcessingBookId));
                    } else if (currentProcessingBookId) {
                        // Fetch the completed book details
                        try {
                            const bookResponse = await fetch(`${domainUrl}/api/books/${currentProcessingBookId}`, {
                                method: "GET",
                                headers: {
                                    "Authorization": `Bearer ${idToken}`,
                                    "Content-Type": "application/json",
                                },
                            });

                            if (bookResponse.ok) {
                                const bookData = await bookResponse.json();
                                // Update books list with the completed book
                                setBooks(prevBooks => {
                                    const existingBookIndex = prevBooks.findIndex(b => b.id === currentProcessingBookId);
                                    if (existingBookIndex >= 0) {
                                        const updatedBooks = [...prevBooks];
                                        updatedBooks[existingBookIndex] = bookData;
                                        return updatedBooks;
                                    }
                                    return prevBooks; // Don't add a new book since it should already be in the list
                                });
                                
                                // Save flashcards to localStorage
                                localStorage.setItem(`flashcards_${currentProcessingBookId}`, JSON.stringify(bookData.flashCards || []));
                            }
                        } catch (error) {
                            console.error("Error fetching completed book:", error);
                        }
                    }
                    return;
                }

                // Update processing book flashcards if they're available
                if (data.flashCards && data.flashCards.length > 0) {
                    // Create a map of existing flashcards to avoid duplicates
                    const existingFlashcardsMap = new Map(
                        (processingBookFlashcards || []).map(card => [card.q, card])
                    );
                    
                    // Add only new flashcards to the array
                    const updatedFlashcards = [...(processingBookFlashcards || [])];
                    data.flashCards.forEach(card => {
                        if (!existingFlashcardsMap.has(card.q)) {
                            updatedFlashcards.push(card);
                        }
                    });
                    
                    setProcessingBookFlashcards(updatedFlashcards);
                    
                    // Update the book in the list with new flashcards
                    setBooks(prevBooks => {
                        const existingBookIndex = prevBooks.findIndex(b => b.id === data.bookId);
                        if (existingBookIndex >= 0) {
                            const updatedBooks = [...prevBooks];
                            updatedBooks[existingBookIndex] = {
                                ...updatedBooks[existingBookIndex],
                                flashcards: updatedFlashcards
                            };
                            return updatedBooks;
                        }
                        return prevBooks;
                    });
                    
                    // Update localStorage
                    localStorage.setItem(`flashcards_${data.bookId}`, JSON.stringify(updatedFlashcards));
                }

                    setProcessingMessage(getRandomLoadingString());
                // Only wait if we need to continue polling
                if (polling) {
                    await new Promise((resolve) => setTimeout(resolve, POLLING_INTERVAL));
                }
            } catch (error) {
                console.error("Error during polling:", error);
                polling = false;
                setIsPolling(false);
                setProcessingBookFlashcards(null);
            }
        }
    };


    const handleBookClick = async (bookId) => {
        const currentBook = books.find(book => book.id === bookId);
        if (!userLoggedIn) {
            setFlashCards(currentBook.flashcards || []);
            setCurrentFlashcardIndex(0);
            setShowAnswer(false);
            setCurrentBook(currentBook);
            setSelectedRating(currentBook.flashcards?.[0]?.rating || null);
            return;
        }

        try {
            const idToken = await auth.currentUser.getIdToken();
            logEvent(analytics, "book_clicked", {bookId: bookId});

            // Fetch both book details and feedbacks in parallel
            const [bookResponse, feedbackResponse] = await Promise.all([
                fetch(`${domainUrl}/api/books/${bookId}`, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                        "Content-Type": "application/json",
                    },
                }),
                fetch(`${domainUrl}/api/books/${bookId}/feedback`, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                        "Content-Type": "application/json",
                    },
                })
            ]);

            if (!bookResponse.ok) {
                logEvent(analytics, "book_details_fetch_error", {bookId: bookId});
                throw new Error("Failed to fetch book details");
            }

            const [bookData, feedbackData] = await Promise.all([
                bookResponse.json(),
                feedbackResponse.ok ? feedbackResponse.json() : { feedback: [] }
            ]);

            // Apply feedbacks to flashcards
            const flashcardsWithFeedback = bookData.flashCards.map(card => {
                const feedback = feedbackData.feedback.find(f => f.orderNum === card.orderNum);
                if (feedback) {
                    return { ...card, rating: feedback.rating };
                }
                return card;
            });

            setFlashCards(flashcardsWithFeedback);
            localStorage.setItem(`flashcards_${bookId}`, JSON.stringify(flashcardsWithFeedback));
            setCurrentFlashcardIndex(0);
            setShowAnswer(false);
            setCurrentBook({ ...bookData, flashCards: flashcardsWithFeedback });
            setSelectedRating(flashcardsWithFeedback[0]?.rating || null);

            logEvent(analytics, "book_details_fetched", {bookId: bookId});
        } catch (error) {
            console.error("Error fetching book details:", error);
            // If API call fails, use cached data if available
            const savedFlashcards = localStorage.getItem(`flashcards_${bookId}`);
            if (savedFlashcards) {
                const parsedFlashcards = JSON.parse(savedFlashcards);
                setFlashCards(parsedFlashcards);
                setCurrentFlashcardIndex(0);
                setShowAnswer(false);
                setCurrentBook(currentBook);
                setSelectedRating(parsedFlashcards[0]?.rating || null);
            }
        }
    };

    const handleFlipCard = () => {
        logEvent(analytics, `card_flipped_to_${showAnswer ? "question" : "answer"}`, {
            bookId: currentBook.id,
            cardIndex: currentFlashcardIndex
        });
        setShowAnswer(!showAnswer);
    };

    // Add function to get all flashcards from all books
    const getAllFlashcardsWithRatings = () => {
        // Get all book IDs from localStorage
        const allFlashcards = [];
        
        // First add current flashcards if they exist
        if (flashCards.length > 0) {
            allFlashcards.push(...flashCards);
        }
        
        // Then get flashcards from all books in localStorage
        for (const key of Object.keys(localStorage)) {
            if (key.startsWith('flashcards_')) {
                const bookFlashcards = JSON.parse(localStorage.getItem(key));
                // Only add flashcards that aren't from the current book
                if (currentBook && `flashcards_${currentBook.id}` !== key) {
                    allFlashcards.push(...bookFlashcards);
                }
            }
        }
        
        return allFlashcards;
    };

    // Modify the salary calculation function to use all flashcards
    const calculateExpectedSalary = () => {
        const baseSalary = 20000;
        const allFlashcards = getAllFlashcardsWithRatings();
        
        const counts = allFlashcards.reduce((acc, card) => {
            if (card.rating) {
                acc[card.rating]++;
            }
            return acc;
        }, { incorrect: 0, almost: 0, correct: 0 });

        return baseSalary + 
               (counts.incorrect * 5) + 
               (counts.almost * 10) + 
               (counts.correct * 50);
    };

    const handleShuffleClicked = (e) => {
        e.stopPropagation();
        logEvent(analytics, "shuffle_clicked", {bookId: currentBook.id});
        const shuffledFlashcards = [...flashCards].sort(() => Math.random() - 0.5);
        // Preserve ratings when shuffling
        const ratingsMap = flashCards.reduce((acc, card) => {
            if (card.rating) {
                acc[card.q] = card.rating;
            }
            return acc;
        }, {});
        // Apply preserved ratings to shuffled cards
        shuffledFlashcards.forEach(card => {
            if (ratingsMap[card.q]) {
                card.rating = ratingsMap[card.q];
            }
        });
        setFlashCards(shuffledFlashcards);
        setCurrentFlashcardIndex(0);
        setShowAnswer(false);
        setSelectedRating(shuffledFlashcards[0]?.rating || null);
        logEvent(analytics, "flashcards_shuffled", {bookId: currentBook.id});
    }

    const handleRatingClick = (e, rating) => {
        e.stopPropagation(); // Prevent card flip
        setSelectedRating(rating);
        
        // Add feedback to the queue
        const currentCard = flashCards[currentFlashcardIndex];
        setFeedbackQueue(prevQueue => {
            // Check if we already have feedback for this card in the queue
            const existingIndex = prevQueue.findIndex(
                item => item.bookId === currentBook.id && item.orderNum === currentCard.orderNum
            );
            
            const newFeedback = {
                bookId: currentBook.id,
                orderNum: currentCard.orderNum,
                rating: rating,
                timestamp: new Date().toISOString()
            };
            
            if (existingIndex >= 0) {
                // Update existing feedback
                const newQueue = [...prevQueue];
                newQueue[existingIndex] = newFeedback;
                return newQueue;
            }
            // Add new feedback
            return [...prevQueue, newFeedback];
        });
        
        // Update the rating in the flashcard object
        setFlashCards(prevCards => {
            const newCards = [...prevCards];
            const currentCard = newCards[currentFlashcardIndex];
            const cardQuestion = currentCard.q;
            
            // Update all cards with the same question (in case of duplicates)
            newCards.forEach(card => {
                if (card.q === cardQuestion) {
                    card.rating = rating;
                }
            });

            // Update localStorage for the current book
            if (currentBook) {
                // Get existing flashcards from storage
                const savedFlashcards = localStorage.getItem(`flashcards_${currentBook.id}`);
                if (savedFlashcards) {
                    const parsedFlashcards = JSON.parse(savedFlashcards);
                    // Update ratings in stored flashcards
                    parsedFlashcards.forEach(card => {
                        if (card.q === cardQuestion) {
                            card.rating = rating;
                        }
                    });
                    localStorage.setItem(`flashcards_${currentBook.id}`, JSON.stringify(parsedFlashcards));
                }
            }
            
            // Calculate and update the expected salary using all flashcards
            setExpectedSalary(calculateExpectedSalary());
            return newCards;
        });

        logEvent(analytics, "flashcard_rated", {
            bookId: currentBook.id,
            cardIndex: currentFlashcardIndex,
            rating: rating
        });
    };

    // Modify the effect to calculate initial salary when flashcards change or when books change
    useEffect(() => {
        setExpectedSalary(calculateExpectedSalary());
    }, [flashCards, books]);

    const handleNextFlashcard = (e) => {
        e.stopPropagation();
        logEvent(analytics, "next_card_clicked", {bookId: currentBook.id, cardIndex: currentFlashcardIndex});
        if (currentFlashcardIndex < flashCards.length - 1) {
            const nextIndex = currentFlashcardIndex + 1;
            setCurrentFlashcardIndex(nextIndex);
            setShowAnswer(false);
            setSelectedRating(flashCards[nextIndex]?.rating || null);
            logEvent(analytics, "next_card_showing", {bookId: currentBook.id, cardIndex: currentFlashcardIndex});
        }
    };

    const handlePreviousFlashcard = (e) => {
        e.stopPropagation();
        logEvent(analytics, "previous_card_clicked", {bookId: currentBook.id, cardIndex: currentFlashcardIndex});
        if (currentFlashcardIndex > 0) {
            const prevIndex = currentFlashcardIndex - 1;
            setCurrentFlashcardIndex(prevIndex);
            setShowAnswer(false);
            setSelectedRating(flashCards[prevIndex]?.rating || null);
            logEvent(analytics, "previous_card_showing", {bookId: currentBook.id, cardIndex: currentFlashcardIndex});
        }
    };

    const handleLogout = async () => {
        try {
            // Send any remaining feedback before logging out
            if (feedbackQueue.length > 0) {
                await sendFeedbackToBackend(feedbackQueue);
            }

            await signOut(auth);

            // Clear the RecaptchaVerifier instance
            if (window.recaptchaVerifier) {
                window.recaptchaVerifier.clear();
                window.recaptchaVerifier = null;
            }

            console.log("User logged out");
            logEvent(analytics, "logout");
            setUserId(analytics, null);
            setUserLoggedIn(false);
            setFlashCards([]);
            setCurrentBook(null);
            setBooks(sampleBooks);
            setIsPolling(false);
            setUserProfileOpened(false);
            setSelectedRating(null);
            setExpectedSalary(20000); // Reset salary to base amount
            setFeedbackQueue([]); // Clear the feedback queue
            setLastFeedbackSentAt(null); // Reset the last sent timestamp
            
            // Clear localStorage data on logout
            localStorage.removeItem('books');
            localStorage.removeItem('flashcards');
            // Clear any book-specific flashcards
            for (const key of Object.keys(localStorage)) {
                if (key.startsWith('flashcards_')) {
                    localStorage.removeItem(key);
                }
            }
        } catch (error) {
            logEvent(analytics, "logout_error");
            console.error("Error logging out:", error);
            alert("Failed to log out. Please try again.");
            setUserLoggedIn(true);
        }
    };

    const handleDownloadFlashcards = async (type) => {
        setDownloading(type);
        logEvent(analytics, "download_flashcards_start", {bookId: currentBook.id, type: type});
        if (!userLoggedIn) {
            const link = document.createElement('a');
            const fileName = `${currentBook.title}-${type}.txt`;
            link.href = `/downloads/${fileName}`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownloading(null); // Reset the downloading state
            logEvent(analytics, "download_flashcards_finish", {bookId: currentBook.id, type: type});
            return;
        }
        try {
            const idToken = await auth.currentUser.getIdToken();
            const response = await fetch(`${domainUrl}/api/books/${currentBook.id}/downloadFlashcards?type=${type}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                },
            });

            if (!response.ok) {
                logEvent(analytics, "download_flashcards_error", {bookId: currentBook.id, type: type});
                throw new Error(`Failed to download ${type} flashcards`);
            }

            // Create a blob from the response
            const blob = await response.blob();

            // Create a link element and simulate a click to download the file
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${currentBook.title}-${type}.txt`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url); // Cleanup
            logEvent(analytics, "download_flashcards_finish", {bookId: currentBook.id, type: type});
        } catch (error) {
            logEvent(analytics, "download_flashcards_error", {bookId: currentBook.id, type: type});
            console.error(`Error downloading ${type} flashcards:`, error);
        } finally {
            setDownloading(null); // Reset the downloading state
        }
    };

    function getRandomLoadingString() {
        return [
            "Uploading your book...",
            "Processing your book...",
            "Turning your book into flashcards...",
            "Creating memory-boosting flashcards...",
            "Almost there...",
            "Just a moment...",
            "Robot is working...",
            "Robots doing what robots do best...",
            "Any second now...",
            "Just a few more seconds...",
            "1100001,1100001"
        ][Math.floor(Math.random() * 11)];
    }

    function handleTryItNow() {
        logEvent(analytics, "try_it_now_clicked");
        setLanding(false);
    }

    const handleOpenSideMenu = () => {
        if (!sideMenuOpen) {
            logEvent(analytics, "side_menu_opened");
        } else {
            logEvent(analytics, "side_menu_closed");
        }
        setSideMenuOpen(!sideMenuOpen);
    }

    const handleOpenUserProfile = () => {
        if (!userProfileOpened) {
            logEvent(analytics, "user_profile_opened");
        } else {
            logEvent(analytics, "user_profile_closed");
        }
        setUserProfileOpened(!userProfileOpened);
    }

    const handleHomeButtonClicked = () => {
        logEvent(analytics, "home_clicked");
        handleOpenSideMenu();
        if (userProfileOpened) {
            handleOpenUserProfile();
        }
    }

    const handleProfileButtonClicked = () => {
        handleOpenSideMenu();
        handleOpenUserProfile();
    }
    const handleLogoutClicked = () => {
        handleOpenSideMenu();
        handleLogout();
    }

    useEffect(() => {
        setShouldShowUploadButton(!landing && !uploading && !isPolling && !checkProcessingInProgress && !userProfileOpened && !authorizationRequired);
        setShouldShowLoadingMessage(userLoggedIn && (uploading || isPolling || checkProcessingInProgress));
        setShouldShowUserProfile(userLoggedIn && userProfileOpened);
        setShouldShowTutorial(firstTimeLogin);
        setShouldShowBookTokens(userLoggedIn);
        setShouldShowBooks(!userProfileOpened && !landing && !authorizationRequired);
        setShouldShowLanding(!userLoggedIn && landing);
        setShouldShowAuth(!userLoggedIn && !landing && !loading && authorizationRequired);
        setShouldShowLoginButton(!userLoggedIn && !landing && !loading && !authorizationRequired);
        setShouldShowSalary(!landing && !userProfileOpened && !authorizationRequired);
    }, [userLoggedIn, loading, landing, uploading, isPolling, checkProcessingInProgress, userProfileOpened, firstTimeLogin, authorizationRequired, currentLanguage]);

    useEffect(() => {
        if (!userLoggedIn || feedbackQueue.length === 0) return;

        const timeoutId = setTimeout(() => {
            if (lastFeedbackSentAt === null || Date.now() - lastFeedbackSentAt > 5000) {
                sendFeedbackToBackend(feedbackQueue);
            }
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, [userLoggedIn, feedbackQueue, lastFeedbackSentAt]);

    const sendFeedbackToBackend = async (feedbackData) => {
        try {
            const idToken = await auth.currentUser.getIdToken();
            
            // Remove any duplicates before sending
            const uniqueFeedback = feedbackData.reduce((acc, current) => {
                const existingIndex = acc.findIndex(
                    item => item.bookId === current.bookId && item.orderNum === current.orderNum
                );
                if (existingIndex >= 0) {
                    // Keep the most recent feedback for this card
                    if (new Date(current.timestamp) > new Date(acc[existingIndex].timestamp)) {
                        acc[existingIndex] = current;
                    }
                } else {
                    acc.push(current);
                }
                return acc;
            }, []);

            const response = await fetch(`${domainUrl}/api/books/feedback`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ feedback: uniqueFeedback }),
            });

            if (!response.ok) {
                throw new Error("Failed to send feedback");
            }

            // Remove successfully sent feedback from the queue using bookId and orderNum
            setFeedbackQueue(prevQueue => prevQueue.filter(item => 
                !uniqueFeedback.some(sent => 
                    sent.bookId === item.bookId && sent.orderNum === item.orderNum
                )
            ));

            // Update last sent timestamp
            setLastFeedbackSentAt(Date.now());
        } catch (error) {
            console.error("Error sending feedback:", error);
        }
    };

    return <div className="app container pt-3 min-vh-100  d-flex flex-column fill-available">
        {shouldShowTutorial && (
            <Tutorial 
                onClick={(e) => {
            // Close overlay if the user clicks outside the content
            if (e && (e.target.className === "tutorial" || e.target.id === "tutorial-go-button")) {
                closeTutorialOverlay();
            }
                }}
            />
        )}
        
        {showPaymentOverlay && (
            <div className="payment-overlay" onClick={(e) => {
            // Close overlay if the user clicks outside the content
            if (e.target.className === "payment-overlay") {
                closePaymentOverlay();
            }
        }}>
            <div className="payment-overlay-content">
                <p>{paymentOverlayMessage}</p>
            </div>
            </div>
        )}
        {sideMenuOpen && <SideMenu onClick={(e) => {
            // Close overlay if the user clicks outside the content
            if (e.target.className === "side-menu-overlay") {
                handleOpenSideMenu();
            }
        }} onClick1={handleHomeButtonClicked} onClick2={handleProfileButtonClicked}
                                   onClick3={handleLogoutClicked}/>}
        <div className="container bg-gradient text-white rounded-4">
            {shouldShowLoadingMessage &&
                <div className="position-fixed bottom-0 start-0 w-100 text-center p-3 alert alert-secondary m-0 z-2 d-none d-sm-block">
                    <p className="h6">
                        {processingMessage}
                    </p>
                </div>
            }
            <header className="d-flex flex-wrap align-items-center justify-content-between py-2 mb-4 border-bottom">
                <a href="/"
                   className="d-flex align-items-center text-decoration-none">
                    <span className="fs-6 text-white text-uppercase fw-bold">{i18n.t('common.appName')}</span>
                </a>
                {shouldShowBookTokens &&
                    <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center">
                            <span className="me-1">{bookTokens}</span>
                            <i className="bi-book"></i>
                        </div>
                        {shouldShowUploadButton &&
                            <label className="btn btn-success d-sm-none p-1 px-2">
                                <i className="bi-cloud-upload"></i>
                                <input
                                    className="d-none"
                                    type="file"
                                    accept="text/plain application/msword application/vnd.openxmlformats-officedocument.wordprocessingml.document application/pdf"
                                    onClick={handleUploadClicked}
                                    disabled={authorizationRequired}
                                    onChange={handleFileUpload}
                                />
                            </label>
                        }
                        <button className="btn btn-outline-light btn-sm p-1 px-2 border-2"
                            onClick={handleOpenSideMenu}>
                            <i className="bi-list"></i>
                        </button>
                    </div>
                }
                {shouldShowLoginButton &&
                    <div>
                        <button className="btn btn-outline-light btn-sm p-1 px-2 border-2"
                            onClick={handleLoginClicked}>
                            {i18n.t('common.login')}
                        </button>
                    </div>
                }
                {shouldShowUploadButton &&
                    <label className="btn btn-success d-none d-sm-block fw-bold position-absolute start-50 translate-middle-x">
                        <i className="bi-cloud-upload me-2"></i>
                        {i18n.t('common.upload')}
                        <input
                            className="d-none"
                            type="file"
                            accept="text/plain application/msword application/vnd.openxmlformats-officedocument.wordprocessingml.document application/pdf"
                            onClick={handleUploadClicked}
                            disabled={authorizationRequired}
                            onChange={handleFileUpload}
                        />
                    </label>
                }
            </header>
        </div>
        {shouldShowUserProfile &&
            <>
                <div className="d-flex flex-column w-100">
                    <UserProfile/>
                </div>
            </>
        }
        {shouldShowBooks &&
            <div className="container bg-gradient text-white rounded-4 p-2">

                <div className="d-flex gap-2 overflow-scroll p-2">
                    {books.length === 0 && !uploading && !isPolling && !checkProcessingInProgress &&
                        <p className="books-placeholder">{i18n.t('books.noBooksPlaceholder')}</p>}
                    {books.map((book) => (
                        <div
                            key={book.id}
                            className={`card p-1 border-3 rounded-3 overflow-hidden flex-shrink-0 book position-relative ${currentBook && currentBook.id === book.id ? "active-book" : ""}`}
                            style={{width: '80px', height: '100px'}}
                            onClick={() => handleBookClick(book.id)}
                        >
                            <div className="card-title book-title"
                                 style={{fontSize: '10px'}}>{book.title}</div>
                            {(uploading || isPolling || checkProcessingInProgress) && book.id === books[0]?.id && (
                                <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center" 
                                     style={{background: 'rgba(255,255,255,0.8)'}}>
                                    <div className="spinner-border spinner-border-sm text-warning" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {/* Bottom Section: Flashcards */}
                <div className="main-section container d-flex flex-column align-items-center">
                    {currentBook && <h1 className="book-title">{currentBook.title}</h1>}
                    {flashCards.length > 0 && (
                        <div
                            className="flashcard-section d-flex flex-column align-items-center w-100">
                            <div className="flashcard" onClick={handleFlipCard}>
                                <button className="btn btn-sm position-absolute top-0 start-0 shuffle-button" onClick={handleShuffleClicked}>
                                    <i className="bi-shuffle"></i>
                                </button>
                                <div className="flashcard-label">{showAnswer ? "A" : "Q"}</div>
                                {showAnswer ? (
                                    <p className="flashcard-answer">{flashCards[currentFlashcardIndex].a}</p>
                                ) : (
                                    <p className="flashcard-question">{flashCards[currentFlashcardIndex].q}</p>
                                )}
                                {showAnswer && (
                                    <div className="container d-flex justify-content-evenly gap-2 m-2" onClick={e => e.stopPropagation()}>
                                        <div 
                                            className="container form-check d-flex border border-danger border-2 rounded p-1 cursor-pointer"
                                            onClick={(e) => handleRatingClick(e, 'incorrect')}
                                        >
                                            <input
                                                className="form-check-input mx-1"
                                                type="radio"
                                                name="answerRating"
                                                id="ratingIncorrect"
                                                checked={selectedRating === 'incorrect'}
                                                onChange={() => {}}
                                            />
                                            <label className="form-check-label text-danger" htmlFor="ratingIncorrect">
                                                {i18n.t('flashcard.rating.incorrect')}
                                            </label>
                                        </div>
                                        <div 
                                            className="container form-check border border-warning border-2 rounded p-1 cursor-pointer"
                                            onClick={(e) => handleRatingClick(e, 'almost')}
                                        >
                                            <input
                                                className="form-check-input mx-1"
                                                type="radio"
                                                name="answerRating"
                                                id="ratingAlmost"
                                                checked={selectedRating === 'almost'}
                                                onChange={() => {}}
                                            />
                                            <label className="form-check-label text-warning" htmlFor="ratingAlmost">
                                                {i18n.t('flashcard.rating.almost')}
                                            </label>
                                        </div>
                                        <div 
                                            className="container form-check border border-success border-2 rounded p-1 cursor-pointer"
                                            onClick={(e) => handleRatingClick(e, 'correct')}
                                        >
                                            <input
                                                className="form-check-input mx-1"
                                                type="radio"
                                                name="answerRating"
                                                id="ratingCorrect"
                                                checked={selectedRating === 'correct'}
                                                onChange={() => {}}
                                            />
                                            <label className="form-check-label text-success" htmlFor="ratingCorrect">
                                                {i18n.t('flashcard.rating.correct')}
                                            </label>
                                        </div>
                                    </div>
                                )}
                                <div className="previous-button-container">
                                    <button
                                        className="previous-button"
                                        onClick={handlePreviousFlashcard}
                                        disabled={currentFlashcardIndex === 0}
                                        hidden={currentFlashcardIndex === 0}
                                    >
                                        {"<"}
                                    </button>
                                </div>
                                <div className="next-button-container">
                                    <button
                                        className="next-button"
                                        onClick={handleNextFlashcard}
                                        disabled={currentFlashcardIndex === flashCards.length - 1}
                                        hidden={currentFlashcardIndex === flashCards.length - 1}
                                    >
                                        {'>'}
                                    </button>
                                </div>

                            </div>


                            <div
                                className="download-buttons d-flex w-100 justify-content-center gap-2 p-2">
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => handleDownloadFlashcards("anki")}
                                    disabled={downloading}
                                ><IoCloudDownloadOutline style={{marginRight: '8px'}}/>Anki
                                </button>

                                <button
                                    className="btn btn-secondary"
                                    onClick={() => handleDownloadFlashcards("quizlet")}
                                    disabled={downloading}
                                ><IoCloudDownloadOutline style={{marginRight: '8px'}}/>Quizlet
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                {/*</main>*/}

            </div>
        }
        {shouldShowLanding &&
            <Landing onClick={() => handleTryItNow()}/>
        }
        {shouldShowAuth && <div className="d-flex flex-column w-100">
            <AuthWithFirebase/>
        </div>
        }

        {shouldShowSalary && <div className="d-flex flex-column align-items-center mb-4">
             <small className="text mb-1 cursor-pointer" onClick={() => setShowSalaryInfo(true)}>{i18n.t('landing.salaryTarget')}</small>
            <span className="display-6 fw-bold cursor-pointer" onClick={() => setShowSalaryInfo(true)}>₴{expectedSalary.toLocaleString()}</span>
        </div>}

        {/* Add Language Selector below salary */}
        <div className="d-flex justify-content-center mb-4">
            <LanguageSelector />
        </div>

        {showSalaryInfo && (
            <div className="salary-info-overlay" onClick={() => setShowSalaryInfo(false)}>
                <div className="salary-info-popup" onClick={e => e.stopPropagation()}>
                    <div className="salary-info-content">
                        <p className="mb-0">{i18n.t('salary.growthMessage')}</p>
                        <p className="mb-3">{i18n.t('salary.wrongAnswersMessage')}</p>
                        <button className="btn btn-success w-100" onClick={() => setShowSalaryInfo(false)}>
                            {i18n.t('common.ok')}
                        </button>
                    </div>
                </div>
            </div>
        )}

        <Footer />
    </div>;
}

// Wrap the exported App with LanguageProvider
export default function AppWithLanguage() {
    return (
        <LanguageProvider>
            <App />
        </LanguageProvider>
    );
}

// Footer.js
import React from 'react';
import ModalLink from './ModalLink';
import i18n from './i18n';
import { useLanguage } from './LanguageContext';

function Footer() {
    const { currentLanguage } = useLanguage();
    
    return (
        <>
            <footer className="d-flex justify-content-center p-3 mb-0" style={{
                paddingBottom: 'calc(env(safe-area-inset-bottom, 0px) + 1rem)'
            }}>
                <div>
                    <ModalLink linkText={i18n.t('footer.termsOfService')} title={i18n.t('footer.termsOfService')}>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
{`1. Introduction
Welcome to Book-O-Bot (“Service”, “we”, “us”, or “our”). These Terms of Service (“Terms”) govern your access to and use of our website, applications, and services (collectively, the “Service”). By accessing or using our Service, you agree to be bound by these Terms. If you do not agree, please do not use our Service.

2. Eligibility
By using our Service, you represent and warrant that you are at least 18 years of age and have the legal capacity to enter into these Terms.

3. Service Description
Our Service allows you to upload books and documents, which are processed to generate flashcards based on the content of your uploads. Flashcards are provided in downloadable formats or accessible online. Customers are entitled to process up to 3 documents free of charge. Each additional document processed will incur a fee of $2.

4. Account Registration and Security
Registration: In order to use certain features of our Service, you may be required to create an account.
Account Information: You agree to provide accurate and complete information during the registration process and update it as necessary.
Security: You are responsible for safeguarding your account credentials. Notify us immediately if you suspect any unauthorized use of your account.

5. Payment Terms
Fees: Free users are allowed to process up to 3 documents. Additional documents are billed at $2 per document.
Billing: Payment will be collected via Monobank payment provider at the time of purchase. You agree to provide current, complete, and accurate purchase and account information.
Changes to Fees: We reserve the right to change our fees or billing methods at any time with reasonable notice.

6. User Content and Intellectual Property
Your Content: You retain ownership of the original content you upload. By uploading, you grant us a worldwide, non-exclusive, royalty-free license to process and display your content solely for the purpose of providing the Service.
Flashcards and Output: All flashcards and processed outputs provided to you remain your property, subject to any underlying intellectual property rights of the original works.

7. Acceptable Use
You agree not to:
Upload content that infringes on intellectual property rights or violates any law.
Use the Service for any unlawful, harmful, or fraudulent purposes.
Attempt to bypass or interfere with any security or technical measures of the Service.

8. Disclaimers and Limitation of Liability
Disclaimers: The Service is provided “as is” and “as available” without any warranty of any kind. We do not guarantee that the Service will be error-free, uninterrupted, or free from viruses or other harmful components.
Limitation of Liability: In no event shall Book-O-Bot, its affiliates, or their respective directors, officers, or employees be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of or inability to use the Service.

9. Termination
We reserve the right to suspend or terminate your access to the Service at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users or our business interests.

10. Governing Law
These Terms shall be governed by and construed in accordance with the laws of Ukraine. Any disputes arising under these Terms shall be resolved exclusively in the courts located within Ukraine.

11. Modifications to the Terms
We may update these Terms from time to time. Any changes will be posted on our website and will become effective upon posting. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.

12. Contact Information
For any questions or concerns about these Terms, please contact us at:
Email: business@bookobot.com
Address: Office 1, IQ Centre, Bolsunivska street, Kyiv, Ukraine
`}
                        </pre>
                    </ModalLink>

                    {' | '}

                    <ModalLink linkText={i18n.t('footer.privacyPolicy')} title={i18n.t('footer.privacyPolicy')}>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
{`1. Introduction
This Privacy Notice explains how Book-O-Bot (“we”, “us”, or “our”) collects, uses, discloses, and protects your personal information when you use our Service.

2. Information We Collect
Personal Information: When you register for an account or use our Service, we may collect personal information such as your name, email address, payment information, and any other information you provide.
Usage Data: We automatically collect information about your interactions with the Service, such as IP address, browser type, operating system, pages visited, and other usage details.
Uploaded Documents: Any documents you upload will be processed to create flashcards. While we use automated systems to process your content, please avoid uploading sensitive personal information.

3. How We Use Your Information
Providing the Service: To operate and improve our Service, process your documents, generate flashcards, and communicate with you.
Billing and Payments: To process payments for additional document uploads.
Marketing and Communications: With your consent, we may send you updates, newsletters, or promotional materials. You may opt-out at any time.

4. Sharing of Your Information
Service Providers: We may share your information with third-party service providers who assist us in delivering the Service (e.g., payment processors, hosting services). These providers are bound by confidentiality agreements.
Legal Compliance: We may disclose your information if required by law, regulation, or legal process.
Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.

5. Data Security
We take reasonable measures to protect your personal information against unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet is 100% secure.

6. Your Rights
Access and Correction: You have the right to access and correct your personal information.
Data Deletion: Subject to certain exceptions, you may request the deletion of your personal data.
Opt-Out: You may opt-out of receiving marketing communications by following the unsubscribe instructions provided in those communications.

7. Data Retention
We retain your personal information for as long as necessary to fulfill the purposes for which it was collected, or as required by law.

8. Children's Privacy
Our Service is not directed to individuals under the age of 18. We do not knowingly collect personal information from children. If you believe a child's information has been collected, please contact us immediately.

9. Changes to This Privacy Notice
We may update this Privacy Notice from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of the Service constitutes acceptance of the updated notice.

10. Contact Us
If you have any questions or concerns about this Privacy Notice or our data practices, please contact us at:
Email: business@bookobot.com
Address: Office 1, IQ Centre, Bolsunivska street, Kyiv, Ukraine`}
                        </pre>
                    </ModalLink>
                    {' | '}
                    <ModalLink linkText={i18n.t('footer.refundPolicy')} title={i18n.t('footer.refundPolicy')}>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
{`1. Overview
Our Refund Policy outlines the conditions under which you may be eligible for a refund for paid document processing services.

2. Eligibility for Refunds
Processing Fees: Refunds may be considered for charges incurred beyond the free allotment (3 free documents) if the Service fails to deliver the promised functionality due to a fault on our part.
Service Issues: If you experience technical issues that prevent successful processing of a document, please contact us within 14 days of the transaction.

3. Non-Refundable Circumstances
User Error: Refunds will not be issued for errors caused by incorrect uploads, formatting issues, or if the document provided does not conform to the acceptable use guidelines.
Change of Mind: Requests for refunds based solely on a change of mind or dissatisfaction with the flashcard output (where the Service has functioned as intended) are not eligible.

4. How to Request a Refund
Contact Us: To request a refund, please email us at business@bookobot.com with your account details, transaction information, and a description of the issue.
Review Process: We will review your request and respond within 5 business days. If a refund is approved, it will be issued using the same payment method used for the original transaction.

5. Modifications to the Refund Policy
We reserve the right to modify this Refund Policy at any time. Any changes will be posted on our website and will apply only to transactions made after the effective date of the changes.

6. Contact Information
For questions regarding our Refund Policy, please contact us at:
Email: business@bookobot.com
Address: Office 1, IQ Centre, Bolsunivska street, Kyiv, Ukraine`}
                        </pre>
                    </ModalLink>
                </div>
            </footer>
        </>
    );
}

export default Footer;

import React, { useState, useEffect, useRef } from 'react';
import i18n, { setLanguage } from '../i18n';
import { useLanguage } from '../LanguageContext';

function LanguageSelector() {
    const { currentLanguage } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
        setIsOpen(false);
        window.dispatchEvent(new Event('languageChanged'));
    };

    return (
        <div className="dropdown" ref={dropdownRef}>
            <button 
                className="btn btn-sm btn-outline-light border-2 d-flex align-items-center gap-1 p-1 px-2"
                onClick={() => setIsOpen(!isOpen)}
                type="button"
            >
                {currentLanguage === 'uk' ? 'UA' : 'EN'}
                <i className="bi-chevron-down"></i>
            </button>
            {isOpen && (
                <div className="position-absolute bg-white rounded-1 shadow-sm" style={{ zIndex: 1000, minWidth: '80px' }}>
                    <button
                        className={`btn btn-sm w-100 text-start ${currentLanguage === 'uk' ? 'active' : ''}`}
                        onClick={() => handleLanguageChange('uk')}
                    >
                        UA
                    </button>
                    <button
                        className={`btn btn-sm w-100 text-start ${currentLanguage === 'en' ? 'active' : ''}`}
                        onClick={() => handleLanguageChange('en')}
                    >
                        EN
                    </button>
                </div>
            )}
        </div>
    );
}

export default LanguageSelector;
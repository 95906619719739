import React, { createContext, useEffect, useState, useContext } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./App";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [firstTimeLogin, setFirstTimeLogin] = useState(false);
    const [tempEmail, setTempEmail] = useState("");
    const [authMessage, setAuthMessage] = useState("");

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
            setUserLoggedIn(currentUser
                // && currentUser.emailVerified
            );
        });

        return () => unsubscribe();
    }, []);

    return (
        <AuthContext.Provider value={{ user, loading, userLoggedIn, setUserLoggedIn, showOverlay, setShowOverlay, firstTimeLogin, setFirstTimeLogin, tempEmail, setTempEmail, authMessage, setAuthMessage }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
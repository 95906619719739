import React, { createContext, useContext, useState, useEffect } from 'react';
import i18n, { setLanguage } from './i18n';

const LanguageContext = createContext();

export function LanguageProvider({ children }) {
    const [currentLanguage, setCurrentLanguage] = useState(i18n.locale);

    useEffect(() => {
        const handleLanguageChange = () => {
            setCurrentLanguage(i18n.locale);
        };

        window.addEventListener('languageChanged', handleLanguageChange);
        return () => window.removeEventListener('languageChanged', handleLanguageChange);
    }, []);

    return (
        <LanguageContext.Provider value={{ currentLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
}

export function useLanguage() {
    return useContext(LanguageContext);
} 